import React from 'react'
import Contact from '../components/Contact'
// import Nav from '../components/Header'
import Footer from '../components/Footer'

export default function ContactUs() {
  return (
    <div>
        {/* <Nav /> */}
      <Contact />
      <Footer />
    </div>
  )
}
